import React from 'react';

interface InformalAnswerProps {
  questionId: number;
  answer: number | undefined;
  informalAnswer: string | undefined;
  hasInformalQuestion: boolean;

  handleSelect: (questionId: number, answer?: number, informalAnswer?: string) => void;
}

const InformalAnswer = ({
  questionId,
  answer,
  informalAnswer,
  handleSelect,
  hasInformalQuestion,
}: InformalAnswerProps) => {
  return (
    <div className={`${hasInformalQuestion === true ? '' : 'hidden'} survey-informal-answer-container`}>
      <textarea
        className="survey-informal-answer"
        placeholder="Vapaamuotoinen vastaus..."
        rows={7}
        value={informalAnswer || ''}
        onChange={({ target }) => handleSelect(questionId, answer, target.value)}
      />
    </div>
  );
};

export default InformalAnswer;
