import React from 'react';

interface FinishedProps {
  title: string;
  message: string;

  handleSubmitReply: () => void;
}

const Finished = ({ title, message, handleSubmitReply }: FinishedProps) => {
  handleSubmitReply();
  return (
    <div className="survey-answering-container">
      <h1>{title}</h1>
      <p>{message}</p>
    </div>
  );
};

export default Finished;
