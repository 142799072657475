import React from 'react';

import InformalAnswer from '../InformalAnswer';
import type { SurveyQuestionAnswerQuestionConfigurationDataOptions as Options } from '../types/Survey';

interface SelectProps {
  questionId: number;
  data: Options[];
  answerId: number | undefined;
  informalAnswer: string | undefined;
  hasInformalQuestion: boolean;
  preview?: boolean;

  handleSelect: (questionId: number, answer?: number, informalAnswer?: string) => void;
}

const Select = ({
  questionId,
  answerId,
  data,
  informalAnswer,
  hasInformalQuestion,
  preview,
  handleSelect,
}: SelectProps) => {
  const [maxLengthAnswer, setMaxLengthAnswer] = React.useState<number>(0);
  const numberAnswer = data.length;
  const myContainer = React.createRef<HTMLDivElement>();
  // TODO: this should work different when creating snapshot
  const [loading, setLoading] = React.useState<boolean>(true);
  const [CSS, setCSS] = React.useState<string>('survey-select-container');

  // get the longest answer
  React.useEffect(() => {
    setMaxLengthAnswer(Math.max(...data.map((q) => q.text.length), 0));
  }, []);

  // update the CSS according to the longuest answer
  React.useEffect(() => {
    chooseStyleParent();
    setLoading(false);
  }, [maxLengthAnswer]);

  /**
   * function chooseStyleParent()           20 = fontSize Apple choosen by default here + 1 for margin  / in maxLengthAnswer + 1 character for padding
   *   If the lenght of (number of answer * (20 + 1)  * the lenght of the longest answer) < width of the div parent
   * then CSS to row  Else CSS to Column
   * NB : possible Improvment - change the font size of the police dynamically according length and number of answer
   */
  const chooseStyleParent = () => {
    if (
      myContainer?.current?.offsetWidth &&
      myContainer?.current?.offsetWidth < (maxLengthAnswer + 1) * numberAnswer * 21
    ) {
      setCSS('survey-select-container-columns');
    } else {
      setCSS('survey-select-container');
    }
  };

  return (
    <div className="survey-question-container">
      {loading && !preview && <h1>LOADING ...</h1>}
      <div ref={myContainer} className={CSS}>
        {data.map((q) => {
          return (
            <button
              key={`${questionId}-${q.value}`}
              id={`select-${q.value}`}
              data-testid={`select-${q.value}`}
              className={`survey-select-button focus:outline-none focus:shadow-outline  ${
                q.value === answerId ? 'survey-select-selected-button' : ''
              }`}
              type="button"
              onClick={() => handleSelect(questionId, q.value)}
            >
              <span
                className={`survey-select-option hover:bg-blue-200 focus:shadow-outline ${
                  q.value === answerId ? 'survey-select-option-selected' : ''
                }`}
              >
                {q.text}
              </span>
            </button>
          );
        })}
      </div>
      <InformalAnswer
        informalAnswer={informalAnswer}
        questionId={questionId}
        answer={answerId}
        handleSelect={handleSelect}
        hasInformalQuestion={hasInformalQuestion}
      />
    </div>
  );
};

export default Select;
