import React from 'react';

import AnsweringHelper from './helper/AnsweringHelper';
import Title from './Title';
import ProgressBar from './ProgressBar';
import Description from './Description';
import Question from './Question';
import Finished from './Finished';
import SurveyRequest from './SurveyRequest';
import Default from './Default';
import SurveyActions from './SurveyActions';
import type { Survey, SurveyReply } from './types/Survey';

interface AnsweringProps {
  survey: Survey;
  answerHash: string;
  predefinedAnswer: number | undefined;
  isSurveyAnswered: boolean;
}

interface AnsweringState {
  totalSteps: number;
  stepIndex: number;
  answers: SurveyReply[];
}

class Answering extends React.Component<AnsweringProps, AnsweringState> {
  constructor(props: AnsweringProps) {
    super(props);

    const { survey, predefinedAnswer } = this.props;
    const answers: SurveyReply[] = [];

    if (predefinedAnswer !== undefined && AnsweringHelper.getQuestion(0, survey)) {
      const predefinedQuestion = AnsweringHelper.getQuestion(0, survey);
      const predefinedQuestionId = predefinedQuestion?.id ? predefinedQuestion.id : 0;

      const predefinedSurveyReply: SurveyReply = {
        questionId: predefinedQuestionId,
        answer: predefinedAnswer,
        informalAnswer: undefined,
      };
      answers.push(predefinedSurveyReply);
    }

    this.state = {
      stepIndex: answers.length > 0 ? 1 : 0,
      answers,
      totalSteps: survey.questions ? AnsweringHelper.getTotalSteps(survey.questions) : 0,
    };
  }

  handleSelect = async (questionId: number, answer?: number, informalAnswer?: string) => {
    const { survey } = this.props;
    const { answers, stepIndex } = this.state;
    let hasInformal = false;

    if (survey && survey.questions !== null) {
      const currentQuestion = survey.questions.find((q) => q.id === questionId);
      hasInformal = AnsweringHelper.hasInformalAnswerEnabled(stepIndex, survey);
      let newAnswer;
      const oldAnswer = answers.find((a) => a.questionId === questionId);
      if (!oldAnswer) {
        newAnswer = {
          questionId,
          answer: answer !== undefined ? answer : -1,
          informalAnswer,
        };
      } else {
        newAnswer = {
          questionId: oldAnswer.questionId,
          answer: answer !== undefined ? answer : -1,
          informalAnswer:
            informalAnswer === undefined && !oldAnswer.skipped ? oldAnswer.informalAnswer : informalAnswer,
        };
      }

      if (currentQuestion && newAnswer) {
        this.setState({
          answers: [
            ...answers.filter((a) => a.questionId !== questionId),
            {
              ...newAnswer,
            },
          ],
        });
      }
    }

    // Move to the next question
    if (hasInformal === false) {
      this.handleNextQuestion();
    }
  };

  handleSkipQuestion(questionId: number) {
    const { answers } = this.state;

    this.setState({
      answers: [
        ...answers.filter((a) => a.questionId !== questionId),
        {
          questionId,
          skipped: true,
        },
      ],
    });
    this.handleNextQuestion();
  }

  handleNextQuestion() {
    const { totalSteps } = this.state;

    // Make sure the index doesn't increment over the totalSteps
    this.setState(({ stepIndex }) => ({
      stepIndex: stepIndex < totalSteps ? stepIndex + 1 : totalSteps - 1,
    }));
  }

  handlePreviousQuestion() {
    this.setState(({ stepIndex }) => ({
      stepIndex: stepIndex > 0 ? stepIndex - 1 : 0,
    }));
  }

  handleSubmitReply() {
    const { answers } = this.state;
    const { answerHash } = this.props;
    if (answers) {
      SurveyRequest.postReply(answers, answerHash);
    }
  }

  render() {
    const { stepIndex, answers, totalSteps } = this.state;
    const { survey, isSurveyAnswered } = this.props;

    if (survey && stepIndex < totalSteps && !isSurveyAnswered) {
      const question = AnsweringHelper.getQuestion(stepIndex, survey);
      const answer = AnsweringHelper.getAnswerForStep(stepIndex, survey, answers);

      return (
        <div id="survey-answering-container" className="survey-answering-container">
          <Title title={survey.title} />
          <ProgressBar step={stepIndex} totalSteps={totalSteps} />
          <Description onLandingPage={stepIndex === 0} description={survey.description} />
          <Question
            question={question}
            handleSelect={this.handleSelect}
            answers={answers}
            hasInformalQuestion={AnsweringHelper.hasInformalAnswerEnabled(stepIndex, survey)}
          />
          <SurveyActions
            stepIndex={stepIndex}
            survey={survey}
            answers={answers}
            handleNextQuestion={(...args) => this.handleNextQuestion(...args)}
            handlePreviousQuestion={(...args) => this.handlePreviousQuestion(...args)}
            handleSkipQuestion={(...args) => this.handleSkipQuestion(...args)}
            isQuestionSkippable={Boolean(!answer && question?.optional)}
            questionId={question?.id}
          />
        </div>
      );
    }
    if (survey && stepIndex === totalSteps) {
      return (
        <Finished
          title={survey.completionTitle}
          message={survey.completionMessage}
          handleSubmitReply={() => this.handleSubmitReply()}
        />
      );
    }
    if (survey && isSurveyAnswered) {
      return (
        <Finished
          title={survey.completionTitle}
          message={survey.completionMessage}
          handleSubmitReply={() => this.handleSubmitReply()}
        />
      );
    }
    return <Default message="404" />;
  }
}

export default Answering;
