import React from 'react';

import AnsweringHelper from './helper/AnsweringHelper';
import QuestionDescription from './QuestionDescription';
import Smileys from './QuestionTypes/Smileys';
import Select from './QuestionTypes/Select';
import type { SurveyQuestionAnswerQuestion as SurveyQuestion, SurveyReply } from './types/Survey';

interface QuestionProps {
  question: SurveyQuestion | undefined;
  answers: SurveyReply[];
  hasInformalQuestion: boolean;

  handleSelect: (questionId: number, answer?: number, informalAnswer?: string) => void;
}

const Question = ({ question, handleSelect, answers, hasInformalQuestion }: QuestionProps) => {
  if (question && question.id) {
    switch (question ? question.configuration.type : '') {
      case 'smileys':
        return (
          <>
            <QuestionDescription question={question} />
            <Smileys
              questionId={question.id}
              answerId={AnsweringHelper.getAnswer(question.id, answers)}
              data={question.configuration.options}
              handleSelect={handleSelect}
              hasInformalQuestion={hasInformalQuestion}
              informalAnswer={AnsweringHelper.getInformalAnswer(question.id, answers)}
            />
          </>
        );
      case 'select':
        return (
          <>
            <QuestionDescription question={question} />
            <Select
              questionId={question.id}
              data={question.configuration.options}
              answerId={AnsweringHelper.getAnswer(question.id, answers)}
              handleSelect={handleSelect}
              hasInformalQuestion={hasInformalQuestion}
              informalAnswer={AnsweringHelper.getInformalAnswer(question.id, answers)}
            />
          </>
        );
      case 'requestContact':
        return (
          <>
            <QuestionDescription question={question} />
            <Select
              questionId={question.id}
              data={question.configuration.options}
              answerId={AnsweringHelper.getAnswer(question.id, answers)}
              handleSelect={handleSelect}
              hasInformalQuestion={hasInformalQuestion}
              informalAnswer={AnsweringHelper.getInformalAnswer(question.id, answers)}
            />
          </>
        );
      default:
        return <div>ok</div>;
    }
  }
  return <div>ok</div>;
};

export default Question;
